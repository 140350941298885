/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPlayStoreData = /* GraphQL */ `
  mutation CreatePlayStoreData(
    $input: CreatePlayStoreDataInput!
    $condition: ModelplayStoreDataConditionInput
  ) {
    createPlayStoreData(input: $input, condition: $condition) {
      id
      fileName
      title
      appId
      url
      icon
      developer
      developerId
      priceText
      price
      free
      summary
      scoreText
      score
      extraData
      createdAt
      updatedAt
    }
  }
`;
export const updatePlayStoreData = /* GraphQL */ `
  mutation UpdatePlayStoreData(
    $input: UpdatePlayStoreDataInput!
    $condition: ModelplayStoreDataConditionInput
  ) {
    updatePlayStoreData(input: $input, condition: $condition) {
      id
      fileName
      title
      appId
      url
      icon
      developer
      developerId
      priceText
      price
      free
      summary
      scoreText
      score
      extraData
      createdAt
      updatedAt
    }
  }
`;
export const deletePlayStoreData = /* GraphQL */ `
  mutation DeletePlayStoreData(
    $input: DeletePlayStoreDataInput!
    $condition: ModelplayStoreDataConditionInput
  ) {
    deletePlayStoreData(input: $input, condition: $condition) {
      id
      fileName
      title
      appId
      url
      icon
      developer
      developerId
      priceText
      price
      free
      summary
      scoreText
      score
      extraData
      createdAt
      updatedAt
    }
  }
`;
