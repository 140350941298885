import React, { useState, useEffect } from 'react';
import logo from './fas-banner-2.png';
import './App.css';
import { API, Storage } from 'aws-amplify';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import { listPlayStoreDatas } from './graphql/queries';
import { createPlayStoreData as createPlayStoreDataItemMutation, deletePlayStoreData as deletePlayStoreDataItemMutation } from './graphql/mutations';

const initialFormState = { title: '', summary: '', url: '', icon: ''}

function App() {
  const [playStoreData, setPlayStoreData] = useState([]);
  const [formData, setFormData] = useState(initialFormState);

  useEffect(() => {
    fetchPlayStoreData();
  }, []);

  async function onChange(e) {
    if (!e.target.files[0]) return
    const file = e.target.files[0];
    setFormData({ ...formData, icon: file.name });
    await Storage.put(file.name, file);
    fetchPlayStoreData();
  }

  async function fetchPlayStoreData() {
    const apiData = await API.graphql({ query: listPlayStoreDatas });
    const playStoreDataFromAPI = apiData.data.listPlayStoreDatas.items;
    await Promise.all(playStoreDataFromAPI.map(async item => {
      if (item.icon) {
        const icon = await Storage.get(item.icon);
        item.icon = icon;
      }
      return item;
    }))    
    setPlayStoreData(apiData.data.listPlayStoreDatas.items);
  }

  async function createPlayStoreDataItem() {
    if (!formData.title || !formData.summary) return;
    await API.graphql({ query: createPlayStoreDataItemMutation, variables: { input: formData } });
    if (formData.icon) {
      const icon = await Storage.get(formData.icon);
      formData.icon = icon;
    }    
    setPlayStoreData([ ...playStoreData, formData ]);
    setFormData(initialFormState);
  }

  async function deletePlayStoreDataItem({ id }) {
    const newPlayStoreDataArray = playStoreData.filter(item => item.id !== id);
    setPlayStoreData(newPlayStoreDataArray);
    await API.graphql({ query: deletePlayStoreDataItemMutation, variables: { input: { id } }});
  }

  return (
    <div className="App">
      <h1>Welcome to Free App Showroom</h1>
      <div
        style={{
          justifyContent: "left",
          alignItems: "left",
        }}>
          <img src={logo} className="App-logo" alt="logo" style={{height: 200, width: 200}} /><br/>
      <input
        onChange={e => setFormData({ ...formData, 'title': e.target.value})}
        placeholder="Title"
        value={formData.title}
        maxLength="100"
        size="35"
      /><br/>
      <textarea
        onChange={e => setFormData({ ...formData, 'summary': e.target.value})}
        placeholder="Summary"
        rows="4" cols="50"
        value={formData.summary}
      /><br/>
      <input
        onChange={e => setFormData({ ...formData, 'url': e.target.value})}
        placeholder="URL"
        value={formData.url}
        size="35"
      /><br/>
      <input  type="file"  onChange={onChange}/>
      <button onClick={createPlayStoreDataItem}>Add</button><br/>
      </div>
      <hr/>
      <div style={{marginBottom: 30}}>
        {
          playStoreData.map(item => (
            <div key={item.id || item.title}>
              <h4><a target="_blank" rel="noopener noreferrer" href={item.url}>{item.title}</a>
              &nbsp; <button onClick={() => deletePlayStoreDataItem(item)}>delete</button></h4>
              <div>{item.summary}</div>
              {item.icon && <img src={item.icon} alt={item.title} style={{width: 400}} />}              
              <hr/>
            </div>
          ))
        }
      </div>
      <AmplifySignOut />
    </div>
  );
}
export default withAuthenticator(App);
