/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPlayStoreData = /* GraphQL */ `
  query GetPlayStoreData($id: ID!) {
    getPlayStoreData(id: $id) {
      id
      fileName
      title
      appId
      url
      icon
      developer
      developerId
      priceText
      price
      free
      summary
      scoreText
      score
      extraData
      createdAt
      updatedAt
    }
  }
`;
export const listPlayStoreDatas = /* GraphQL */ `
  query ListPlayStoreDatas(
    $filter: ModelplayStoreDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlayStoreDatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fileName
        title
        appId
        url
        icon
        developer
        developerId
        priceText
        price
        free
        summary
        scoreText
        score
        extraData
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
